import { Box, Button, Card, CardContent, Chip, Divider, Stack, Switch, Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import FacebookLogo from '@app/assets/png/logoFacebook.png';
import TencentLogo from '@app/assets/png/Tencent_Cloud.png';
import ZoomLogo from '@app/assets/png/zoomLogo.png';
import ZoomLoginButton from '../components/ZoomLoginButton';
import { getAuthToken } from '../../Broadcast/services/facebook';
import { getMultiStatusToken, getStatusToken, getTencentStatus, tencentActivate, zoomMultiActivate } from '../services';
import addNotification from '../../../utils/addNotification';
import { useQuery } from 'react-query';
import TencentButton from '../components/TencentButton';
import ZoomLogoutButton from '../components/ZoomLogoutButton';
import TencentLogoutButton from '../components/TencentLogoutButton';
import CancelIntegrationButton from '../components/CancelIntegrationButton';

const Integrations = () => {
  const [zoomMultiIsActive, setZoomMultiIsActive] = useState(false);
  const [tencentImportIsActive, setTencentImportIsActive] = useState(false);
  const [tencentIntegrationIsActive, setTencentIntegrationIsActive] = useState(false);
  const [zoomMultiIsDeauthorized, setZoomMultiIsDeauthorized] = useState(true);
  const zoomButtonRef = useRef(null);
  const zoomMultiButtonRef = useRef(null);
  const zoomMultiLogoutButtonRef = useRef(null);
  const zoomLogoutButtonRef = useRef(null);
  const zoomQuery = useQuery('getStatusToken', getStatusToken, {
    retry: false,
    onSuccess: data => {
      if (data.find(account => account.deauthorized)) {
        zoomButtonRef.current.updateState(true);
      } else {
        zoomLogoutButtonRef.current.updateState(false);
      }
    },
    onError: error => {
      const notFound = error.message.includes('404');
      if (notFound) zoomButtonRef.current.updateState(true);
    }
  });
  const zoomMultiQuery = useQuery('getMultiStatusToken', getMultiStatusToken, {
    retry: false,
    onSuccess: data => {
      setZoomMultiIsActive(data.activeImport);
      if (data.deauthorized) {
        zoomMultiButtonRef.current.updateState(true);
      } else {
        zoomMultiLogoutButtonRef.current.updateState(false);
      }
      setZoomMultiIsDeauthorized(data.deauthorized);
    },
    onError: error => {
      const notFound = error.message.includes('404');
      if (notFound) setZoomMultiIsDeauthorized(true);
    }
  });
  const { isLoading } = useQuery('getTencentStatus', getTencentStatus, {
    retry: false,
    onSuccess: data => {
      setTencentImportIsActive(data.activeImport);
      setTencentIntegrationIsActive(data.activeIntegration);
    }
  });
  const [isLoggedWithFacebook, setIsLoggedWithFacebook] = useState(false);
  useEffect(() => {
    if (document.cookie.includes('facebook_token')) {
      setIsLoggedWithFacebook(true);
    }
  }, []);
  const chipText = checked => (checked ? 'HABILITADO' : 'DESABILITADO');
  const chipColor = checked => (checked ? 'secondary' : 'error');
  const onZoomSwitch = evt => {
    try {
      const payload = { activeImport: evt.target.checked };
      zoomMultiActivate(payload);
      setZoomMultiIsActive(evt.target.checked);
    } catch {
      addNotification({ message: 'Falha ao tentar ativar importação.', type: 'danger' });
    }
  };
  const onTencentSwitch = async evt => {
    try {
      const payload = { activeImport: evt.target.checked };
      setTencentImportIsActive(evt.target.checked);
      await tencentActivate(payload);
    } catch {
      addNotification({ message: 'Falha ao tentar ativar importação.', type: 'danger' });
    }
  };
  const cards = [
    {
      title: 'Tencent Cloud',
      image: TencentLogo,
      description:
        'Aprimore sua experiência com o Spalla ao incorporar o Tencent Cloud Com essa integração, todas as gravações produzidas no Tecent RTC serão automaticamente sincronizadas com a Biblioteca de Conteúdos Spalla. Lembre-se de que você pode interromper essa integração a qualquer momento.',
      checked: tencentImportIsActive || false,
      onSwitch: onTencentSwitch,
      switchDisable: !tencentIntegrationIsActive,
      onChangeButton: (
        <TencentButton
          disabled={tencentIntegrationIsActive}
          setTencentImportIsActive={setTencentImportIsActive}
          setTencentIntegrationIsActive={setTencentIntegrationIsActive}
          isLoading={isLoading}
        />
      ),
      onDeauthorizeButton: (
        <TencentLogoutButton
          disabled={!tencentIntegrationIsActive}
          setTencentIntegrationIsActive={setTencentIntegrationIsActive}
          setTencentImportIsActive={setTencentImportIsActive}
          isLoading={isLoading}
        />
      )
    },
    {
      title: 'Facebook Live',
      image: FacebookLogo,
      description:
        'Aprimore sua experiência ao integrar o Spalla stream com o Facebook Live. Com essa integração é possível realizar transmissões simultâneas com o Facebook de maneira rápida e prática. Lembre-se de que você pode interromper essa integração a qualquer momento.',
      onChangeButton: (
        <Button
          color="primary"
          disabled={isLoggedWithFacebook}
          size="large"
          sx={{ width: '100%', '&:disabled': { color: 'common.white', bgcolor: 'muted' } }}
          onClick={() => getAuthToken()}
        >
          Integração
        </Button>
      ),
      onDeauthorizeButton: <CancelIntegrationButton disabled={!isLoggedWithFacebook} onClick={() => getAuthToken()} />
    },
    {
      title: 'Zoom Meetings(Multi-contas)',
      image: ZoomLogo,
      description:
        'Conecte sua conta administradora Zoom com subcontas ao Spalla para sincronizar automaticamente as gravações com a Biblioteca de Conteúdos Spalla.',
      checked: zoomMultiIsActive,
      switchDisable: zoomMultiIsDeauthorized,
      onSwitch: onZoomSwitch,
      onChangeButton: (
        <ZoomLoginButton
          isMultiAccounts
          key={zoomMultiQuery.isLoading}
          buttonText="Integração"
          ref={zoomMultiButtonRef}
          isDeauthorized={zoomMultiIsDeauthorized}
          zoomQuery={zoomMultiQuery}
        />
      ),
      onDeauthorizeButton: (
        <ZoomLogoutButton
          isLogoutButton
          buttonText="Desfazer Integração"
          ref={zoomMultiLogoutButtonRef}
          isDeauthorized={zoomMultiIsDeauthorized}
          zoomQuery={zoomMultiQuery}
        />
      )
    },
    {
      title: 'Zoom Meetings',
      image: ZoomLogo,
      description: 'Conecte diversas contas Zoom ao Spalla para sincronizar automaticamente as gravações com a Biblioteca de Conteúdos Spalla.',
      onChangeButton: (
        <ZoomLoginButton key={zoomQuery.isLoading} buttonText="Integração" ref={zoomButtonRef} isDeauthorized={false} zoomQuery={zoomQuery} />
      ),
      onDeauthorizeButton: (
        <ZoomLogoutButton isLogoutButton buttonText="Desfazer Integração" ref={zoomLogoutButtonRef} isDeauthorized={false} zoomQuery={zoomQuery} />
      )
    }
  ];
  useEffect(() => {
    document.title = 'Integrações | Spalla';
  }, []);
  return (
    <>
      <Card>
        <CardContent>
          <Typography sx={{ color: 'neutral.body', fontSize: 16, fontWeight: 600 }}>Integrações</Typography>
        </CardContent>
      </Card>
      <Stack flexWrap="wrap" direction={{ xs: 'column', md: 'row' }} justifyContent="space-between" gap={3} sx={{ mt: 3 }}>
        {cards.map(card => (
          <Card key={card.title} sx={{ width: { xs: '100%', md: '49%' } }}>
            <CardContent>
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                flexWrap={{ xs: 'wrap', lg: 'nowrap' }}
                sx={{ width: '100%' }}
              >
                <Stack alignItems="center" direction="row" gap={2}>
                  {card.onSwitch && <Switch disabled={card.switchDisable} checked={card.checked} onChange={card.onSwitch} />}
                  <Typography sx={{ color: 'neutral.body', fontSize: 16, fontWeight: 600 }}>{card.title}</Typography>
                </Stack>
                {card.onSwitch && <Chip label={chipText(card.checked)} color={chipColor(card.checked)} />}
              </Stack>
              <Box
                sx={{
                  backgroundImage: `url(${card.image})`,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  height: 189,
                  width: '100%'
                }}
              />
              <Divider sx={{ bgcolor: 'grey.200', mb: 2 }} />
              <Typography sx={{ color: 'neutral.body', minHeight: 80 }}>{card.description}</Typography>
              <Divider sx={{ bgcolor: 'grey.200', my: 3 }} />
              <Stack gap={3}>
                {card.onChangeButton}
                {card.onDeauthorizeButton}
              </Stack>
            </CardContent>
          </Card>
        ))}
      </Stack>
    </>
  );
};

export default Integrations;
